<template>
	<div>
		<div v-if="activeIndex == 'expert'">
			<div class="main">
				<sm-card title="待办订单">
					<div class="flex order" slot="body">
						<div class="flex flex-1 order-item" style="border-right: 1px solid #DDDDDD;margin-right: 20px;">
							<div class="img order-img"> <img src="@assets/osImg/indexpage/companyCount.png" /></div>
							<div class="flex-1 order-content">
								<div class="order-title">故障维修</div>
								<div class="order-tree">高线高线高线高线高线</div>
								<div class="order-time">接单时间：2020-20-20</div>
							</div>
							<div class="order-status">未完成</div>
						</div>
						<div class="flex flex-1 order-item">
							<div class="img order-img"> <img src="@assets/osImg/indexpage/companyCount.png" /></div>
							<div class="flex-1 order-content">
								<div class="order-title">故障维修</div>
								<div class="order-tree">高线高线高线高线高线</div>
								<div class="order-time">接单时间：2020-20-20</div>
							</div>
							<div class="order-status">未完成</div>
						</div>
					</div>
				</sm-card>
				<sm-card title="订单量">
					<div class="flex" slot="body">
						<div class="left ml-20 pt-30">
							<div class="text">{{ title }}</div>
							<div class="text text2">{{ total }}</div>
							<div>
								<i v-if="this.stat == -1" class="el-icon-top r"></i>
								<i v-if="this.stat == 1" class="el-icon-top r"></i>
								<!-- <i v-if="this.stat == 0" class="el-icon-top darkColor"></i> -->
								<span class="r" v-if="this.stat == -1">{{ rate }}</span>
								<span class="g" v-if="this.stat == 1">{{ rate }}</span>
								<span class="h" v-if="this.stat == 0">{{ rate }}</span>
								<span class="text1">{{ foot }}</span>
							</div>
						</div>
						<div>
							<div class="flex jc-between mt-20 mr-10">
								<div class="flex ai-center ml-10">
									<span class="fs-16" v-if="this.type == 0">近一日预警统计</span>
									<span class="fs-16" v-if="this.type == 1">近一周预警统计</span>
									<span class="fs-16" v-if="this.type == 2">近一月预警统计</span>
								</div>

								<div class="flex ai-center hover">
									<div class="ml-20" :class="{ active: this.type === 0 }" @click="changType(0)">
										今日
									</div>
									<div class="ml-20" :class="{ active: this.type === 1 }" @click="changType(1)">
										本周
									</div>
									<div class="ml-20" :class="{ active: this.type === 2 }" @click="changType(2)">
										本月
									</div>
									<div class="ml-20">
										<el-date-picker v-model="timeValue" type="daterange" range-separator="-"
											start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
											@change="changTime(3)">
										</el-date-picker>
									</div>
								</div>
							</div>
							<div class="right" ref="indexChat"></div>
						</div>
					</div>
				</sm-card>
			</div>
		</div>

		<router-view v-else />
	</div>
</template>

<script>
	import smCard from "@c/smCard.vue"
	import smFooter from "@c/smFooter.vue"
	import store from "../../../store/index";
	import configApi from "../../../config/configApi";
	export default {
		components: {
			smCard,
			smFooter,
		},
		data() {
			return {
				activeIndex: "",
				title: "",
				timeValue: "",
				xData: [],
				yData: [],
				total: "",
				rate: "",
				stat: "",
				foot: "",
				type: "",
				zData: [],
				chatsData: [],
				timeFrome: {
					type: "0",
				},
			};
		},
		// watch: {
		// 	"$route.name": {
		// 		handler(val) {
		// 			this.activeIndex = val;
		// 		},
		// 		immediate: true,
		// 	},
		// 	chatsData(val) {
		// 		this.title = val.title;
		// 		this.total = val.total;
		// 		this.rate = val.rate;
		// 		this.stat = val.stat;
		// 		this.foot = val.foot;
		// 		this.type = val.type;
		// 		this.zData = val.data;
		// 		this.init();
		// 	},
		// },
		methods: {
			init() {
				const indexChat = this.$echarts.init(this.$refs.indexChat);
				let option = {
					grid: {
						top: "10%",
						left: "5%",
						right: "5%",
						bottom: "10%",
						// containLabel: true,
					},
					xAxis: {
						name: '时间',
						type: "time",
						splitLine: {
							show: true,
							lineStyle: {
								color: "#dedede",
								type: 'dashed'
							}
						},
						axisLine: {
							show: true,
						},
					},
					yAxis: {
						name: '次数',
						type: "value",
						// minInterval: 1,
						splitLine: {
							show: true,
							lineStyle: {
								color: "#dedede",
								type: 'dashed',
							}
						},
						axisLine: {
							show: true,
						},
					},
					series: [{
						data: this.zData,
						symbolSize: 5,
						type: "line",
						itemStyle: {
							normal: {
								color: '#15B931', //折点颜色
								lineStyle: {
									width: 0.8, // 0.1的线条是非常细的了
									color: "#15B931",
								},
							},
						},
					}, ],
					dataZoom: [{
							show: false,
							realtime: true,

						},
						{
							type: "inside",
							realtime: true,
						},
					],
				};
				indexChat.setOption(option, true);
			},
			changTime(type) {
				let obj = {
					type: type,
					timeValue: this.timeValue,
				};
				this.type = type;
				this.timeFrome = {
					type: obj.type,
					begin: obj.timeValue[0],
					end: obj.timeValue[1],
				};
				this.getChats();

			},
			changType(type) {
				this.type = type;
				this.timeValue = null;
				this.timeFrome.type = String(val);
				this.getChats();
			},
			getChats() {
				this.$get("/backend-api/web/index/spec", this.timeFrome).then((res) => {
					this.chatsData = res.data;
				});
			},
			getOrders(){
				this.$get("/base-api/schedule/list", {isHandled:0}).then((res) => {
					// console.log(res.data)
				});
			}
		},
		mounted() {
			this.getOrders();
			this.getChats();
		}
	};
</script>

<style lang='scss' scoped>
	.right {
		height: 280px;
		width: 1200px;
	}

	.order-item {
		.order-img img {
			width: 94px;
			height: 94px;
		}

		.order-content {
			margin-left: 12px;

			.order-title {
				font-size: 16px;
				font-weight: 500;
				color: #000000;
				font-family: PingFangSC-Medium, PingFang SC;
				margin-bottom: 12px;
			}

			.order-tree {
				font-size: 16px;
				font-weight: 300;
				color: #595959;
				font-family: PingFangSC-Light, PingFang SC;
				margin-bottom: 12px;
			}

			.order-time {
				font-size: 14px;
				font-weight: 300;
				color: #595959;
				font-family: PingFangSC-Light, PingFang SC;
			}
		}

		.order-status {
			width: 100px;
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #4A99F6;
		}
	}
</style>
